<template>
  <div class="pay-result-page">
    <!-- v-if="phoneType != -1" -->
       <p class="title" >支付</p>
    <div class="pay-result-img"></div>
    <div class="pay-result-text">
      您的支付申请已提交，预计30分钟内有还款结果，请前往账单中查看
    </div>
    <div class="pay-result-btn" @click="goSuccessful">确认</div>
  </div>
</template>

<script>
import { onMounted, toRefs, reactive } from 'vue'
import { Toast } from 'vant'
import { Jump } from '@/utils/routh'
import { routerTag } from '../../utils/index'
export default {
  name: 'Index',
  setup () {
    const state = reactive({ phoneType: null })
    // 还款完成
    const goSuccessful = () => {
      // 跳转到账单页
      // window.TDAPP.onEvent("支付完成页-点击完成-进入");
      // APP.BACK(-4)
      APP.Back(4);
    }
    onMounted(() => {
      // let phoneType
      // phoneType = localStorage.getItem('deviceType')
      // console.log(phoneType, '机型')
      // state.phoneType = phoneType
      // if (phoneType == -1) {
        document.title("支付")
        APP.HideNavBar() //是否隐藏导航栏
      // } else {
      //   APP.ShowNavBar()
      // }
    })
    return {
      ...toRefs(state),
      goSuccessful
    }
  }
}
</script>
<style scoped lang='less' src='./index.less'></style>
